import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

const styles = {
  image: {
    width: { xs: "80vw", lg: "60vw" },
    maxWidth: "960px",
    height: "auto",
  },
};

const SimpleImage = ({ imageUrl, alt }) => {
  return <Box sx={styles.image} component="img" src={imageUrl} alt={alt} />;
};

SimpleImage.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};
export default SimpleImage;
