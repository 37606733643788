import { Box } from "@mui/material";
import * as React from "react";
import VerticalMenu from "../../../components/VerticalMenu";
import HorizontalMenu from "../../../components/HorizontalMenu";
import Container from "@mui/material/Container";
import Helmet from "react-helmet";
import favicon from "../../../images/favicon.ico";
import PageTitle from "../../../components/PageComponents/PageTitle";
import Paragraph from "../../../components/PageComponents/Paragraph";
import ParagraphTitle from "../../../components/PageComponents/ParagraphTitle";
import SupportButton from "../../../components/SupportButton";
import SimpleImage from "../../../components/PageComponents/SimpleImage";
import map1 from "../../../images/mapExample1.png";
import map2 from "../../../images/mapExample2.png";
import username from "../../../images/mapboxUserName.png";
import style from "../../../images/mapboxStyle.png";
import accessToken from "../../../images/mapboxToken.png";
import createToken from "../../../images/createToken.png";
import tokenPermissions from "../../../images/tokenPermissions.png";

import ParagraphList from "../../../components/PageComponents/ParagraphList";
const styles = {
  pageStyles: {
    marginBottom: "100px",
  },
  pageContent: {
    display: "flex",
  },
  marginTop: {
    marginTop: "30px",
  },
};

const IndexPage = () => {
  return (
    <main style={styles.pageStyles}>
      <HorizontalMenu />
      <Box sx={styles.pageContent}>
        <VerticalMenu page={8} />
        <Container maxWidth="md" sx={{ wordWrap: "break-word" }}>
          <PageTitle text="Mapbox integration" />
          <Paragraph text="When your data is delivered, you can directly see your data in GLOBHE's marketplace. The data is visualised using Mapbox on your delivery page. You can also send your maps directly to your mapbox account through the platform and without downloading and uploading the data." />
          <Paragraph text="In the snapshot below, you can see how it looks. You choose the product type that you wish to see, and you will see your map on the left. Note that the product types available in your delivery only correspond to the ones you have requested in your order." />
          <SimpleImage imageUrl={map1} />
          <Box sx={styles.marginTop}>
            <ParagraphTitle text="How to send your maps to Mapbox" />
            <Paragraph text="Sending your maps to Mapbox is straightforward. Follow the steps below." />
            <ol>
              <ParagraphList text="Click on 'Integration with other apps' to open the menu" />
              <ParagraphList text="Click on 'Mapbox'" />
              <ParagraphList text="Insert your details'" />
            </ol>
            <Paragraph
              text="Since this is your account we do not store your information. You
            have to insert your tokens and secret tokens everytime you need to
            send data to your account."
            />
            <SimpleImage imageUrl={map2} />
          </Box>
          <Box sx={styles.marginTop}>
            <ParagraphTitle text="Where to find this information" />
            <Paragraph text="You can find your mapbox username in your account. Go to https://account.mapbox.com/ to check it out. You will find it like in the snapshot below." />
            <SimpleImage imageUrl={username} />
          </Box>
          <Box sx={styles.marginTop}>
            <Paragraph text="You can find your style url in your mapbox studio. Go to https://studio.mapbox.com//, click on the three vertical points next to your style, and click on details. You can find it like in the snapshot below and you can copy it from there." />
            <SimpleImage imageUrl={style} />
          </Box>
          <Box sx={styles.marginTop}>
            <Paragraph text="You can find your mapbox access token in your account home page. Go to https://account.mapbox.com/ to check it out. You can find it like in the snapshot below. Copy this token." />
            <SimpleImage imageUrl={accessToken} />
          </Box>
          <Box sx={styles.marginTop}>
            <Paragraph text="If you do not have a secret token with write permission, you can generate a new one. Click on 'Create a token' like in the snapshot below." />
            <SimpleImage imageUrl={createToken} />
          </Box>
          <Box sx={styles.marginTop}>
            <Paragraph text="Choose a convenient name and then make sure to check the boxes that are shown below. This will allow the API to write and add files to your mapbox style." />
            <SimpleImage imageUrl={tokenPermissions} />
            <Paragraph text="Copy this token and paste it in the delivery page. Note that you need to store a copy of this token somewhere safe on your computer because you will not be able to retrieve it from Mapbox later." />
          </Box>
          <Box sx={styles.marginTop}>
            <Paragraph text="Once you have inserted all the information above, scroll down, select the map you want to send, and then click on 'Send to mapbox'. Wait few minutes until the map is sent to your mapbox. Larger files require more time." />
          </Box>

          <ParagraphTitle text="Need help" />
          <Paragraph
            text="For questions and inquiries, you are more than welcome to contact
            GLOBHE's tech support and we will get back to you within 24 hours."
          />
          <SupportButton />
        </Container>
      </Box>
      <Helmet>
        <meta charSet="utf-8" />
        <title>GLOBHE&apos;s developers docs</title>
        <link rel="icon" href={favicon} />
      </Helmet>
    </main>
  );
};

export default IndexPage;
